<template>
  <div class="banner-wrap client-width overflow-h">
    <swiper ref="mySwiper" :options="swiperOptions" class="banner-wrap">
      <swiper-slide v-for="(item, index) in bannerList" :key="index">
        <iframe :src="item"></iframe>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "carrousel",
  data() {
    return {
      bannerList: [
        "http://www.cadayun.com/merchant/Visualization/bigData",
        "http://data.cada.cn/main/screens.do"
      ],
      swiperOptions: {
        loop: true,
        speed: 600,
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        observer: true,
        observeParents: true
      }
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  }
};
</script>

<style lang="scss">
.banner-wrap {
  height: 100vh;
  iframe {
    width: 100%;
    height: 100%;
  }
}
.swiper-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  .swiper-slide {
    width: 100%;
    flex: 0 0 100%;
    height: 100%;
  }
}
</style>
